import React from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import Footer from "../../other/Footer";

const RestContent = (props) => {
    const { t, frontURL, onChangeRoute, onShowSpoonLoader, dirSize } = props;
    return (
        <Row id="restContent" className="no-gutters">
            <Col xs={12}>
                <div id="imagesFromLyzkon">
                    <Image fluid src={`${frontURL}/images/collage2-${dirSize.includes("lg") || dirSize.includes("xl") ? "lg" : "xs"}.webp`} alt="collage-images" id="collage" style={{ opacity: 0 }} />
                    <div className="text-center">
                        <Button variant="primary" size="lg" onClick={(e) => onChangeRoute(e, `/${t("url.link.gallery")}`)} className="my-5">
                            {t("home.seeMoreBtn")}
                        </Button>
                    </div>
                </div>
            </Col>

            <Container>
                <Row id="notEnough" className="py-4 text-center">
                    <Col xs={12}>
                        <p id="notEnoughTitle">{t("home.notEnoughTitle")}</p>
                        <p>{t("home.notEnoughText")}</p>
                    </Col>
                    <Col xs={{ offset: 0, span: 12 }} lg={{ offset: 0, span: 4 }} sm={{ offset: 3, span: 6 }}>
                        <a href="https://antykonwent.pl/" target="_blank" rel="noopener noreferrer">
                            <Image src={`${frontURL}/images/sponsors/rafineria_logo.webp`} alt="rafineria antykonwent logo white" fluid />
                            <span className="d-block my-2 text-uppercase">Rafineria</span>
                        </a>
                    </Col>
                    <Col xs={{ offset: 0, span: 12 }} lg={{ offset: 0, span: 4 }} sm={{ offset: 0, span: 6 }}>
                        <a href="http://plomien.lunarii.org/Zakonki/" target="_blank" rel="noopener noreferrer">
                            <Image src={`${frontURL}/images/sponsors/zakon_logo_konwent_low.webp`} alt="zakonki logo" fluid />
                            <span className="d-block my-2 text-uppercase">Zakonki</span>
                        </a>
                    </Col>
                    <Col xs={{ offset: 0, span: 12 }} lg={{ offset: 0, span: 4 }} sm={{ offset: 0, span: 6 }}>
                        <a href="http://ziemiejalowe.pl/" target="_blank" rel="noopener noreferrer">
                            <Image src={`${frontURL}/images/sponsors/ziemie_jalowe.webp`} alt="ziemie jałowe logo" fluid />
                            <span className="d-block my-2 text-uppercase">Ziemie Jałowe</span>
                        </a>
                    </Col>
                    <Col xs={12} lg={{ offset: 3, span: 6 }} className="mt-lg-4 mt-3">
                        <a href="https://www.facebook.com/trojdorado.larp" target="_blank" rel="noopener noreferrer">
                            <Image src={`${frontURL}/images/sponsors/trojdorado.webp`} alt="trójdorado larp logo" fluid />
                            <span className="d-block my-2 text-uppercase">Trójdorado</span>
                        </a>
                    </Col>
                </Row>
                <Row id="sponsors">
                    <Col xs={12}>
                        <p className="px-3" id="sponsorsTitle">
                            {t("home.sponsors")}
                        </p>
                        <div
                            id="sponsorsImg"
                            style={{
                                backgroundImage: `url(${frontURL}/images/sponsors/sponosrs.webp)`,
                            }}
                        ></div>
                    </Col>
                </Row>
            </Container>

            <Col xs={12}>
                <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
            </Col>
        </Row>
    );
};

export default RestContent;
